const fr = {
  menu: {
    openinghours: "Heures d'ouverture",
    login: "Connexion",
    joinstore: "Devenir membre",
    open: "Ouvert",
    closed: "Fermé",
    preorder: "Précommande",
    left: "Restant ",
    soldout: "Épuisé",
    cart: "Panier",
    cartitem: " Article",
    pleasechooseyourmeal: "Veuillez choisir votre plat",
    closedday: "Jour de repos hebdomadaire",
    today: "Aujourd'hui",
    stocklimitreached: "Limite de stock atteint",
    closedtoday: "Fermé aujourd'hui",
    ordertimehaspassed: "L'heure de commande est dépassée",
    thistimeslotisfullybooked: "Ce créneau horaire est complet",
    minuteslater: "minutes plus tard",
    hourslater: "heures plus tard",
    orderedmeals: "Articles commandés",
    addmoremeals: "Ajouter des articles",
  },
  week: {
    sunday: "Dimanche",
    monday: "Lundi",
    tuesday: "Mardi",
    wednesday: "Mercredi",
    thursday: "Jeudi",
    friday: "Vendredi",
    saturday: "Samedi",
  },
  takeAwayTime: {
    expectedpickuptime: "Heure estimée de récupération",
    pickupdate: "Date de récupération",
    pickuptime: "Heure de récupération",
    otherdates: "Autres dates",
    confirm: "Confirmer",
    today: "Aujourd'hui",
    orderfull: "atteint",
    isorderfull: " (Créneau atteint)",
  },
  checkout: {
    cart: "Panier",
    expectedpickuptime: "Heure estimée de récupération",
    cancel: "Annuler",
    notice: "Remarques",
    orderdetails: "Détails de la commande",
    total: "Total",
    memo: "Notes",
    member: "Membre",
    name: "Nom",
    phonenumber: "Numéro de portable",
    receipt: "Reçu",
    uniformnumber: "Numéro d'identification uniforme",
    vehiclenumber: "Identifiant de code-barres",
    submitorder: "Placer une commande",
    today: "Aujourd'hui",
    closedtoday: "Fermé aujourd'hui",
    ordertimehaspassed: "L'heure de commande est dépassée",
    allergiesoringredients:
      "Si vous avez des allergies ou des ingrédients que vous ne souhaitez pas ajouter, veuillez nous en informer.",
    vehiclenumberisincorrect: "Le format de ID de code-barres est incorrect.",
    areyousuretosubmittheorder:
      "Êtes-vous sûr de vouloir soumettre la commande ?",
    businesshourshavechanged:
      "Les horaires d'ouverture ont changé. Veuillez vérifier l'heure à nouveau.",
    areyousureyouwanttodeletethis: "Êtes-vous sûr de vouloir supprimer ?",
    areyousureyouwanttodeletetheentireorder:
      "Êtes-vous sûr de vouloir supprimer la commande entière ?",
    thedatehasbeenupdated: "La date a été mise à jour.",
    minuteslater: "minutes plus tard",
    hourslater: "heures plus tard",
    continueordering: "Continuer à commander",
  },
  login: {
    login: "Se connecter",
    phonenumber: "Numéro de portable",
    password: "De passe",
    forgotpassword: "Mot de passe oublié",
    register: "S'inscrire",
  },
  join: {
    join: "Rejoindre",
    joinstore: "Devenir membre",
    alreadyjoin: "Vous êtes déjà connecté. Vous pouvez rejoindre directement.",
  },
  forgotPassword: {
    forgotpassword: "Mot de passe oublié",
    phonenumber: "Numéro de portable",
    getverifycode: "Obtenir le code",
    retry: "s Réessayer",
    verifycode: "Code de vérification",
    newpassword: "Nouveau mot de passe",
    enterpasswordagain: "Ressaisir le mot de passe",
    pleaseentersamepassword: "Veuillez entrer le même mot de passe",
    save: "Enregistrer",
    pleasetryagainlater: "Veuillez réessayer plus tard",
    pleaseenterphonenumber: "Veuillez entrer votre numéro de téléphone",
    pleasecheckyourmobilemessage: "Veuillez vérifier votre message mobile",
  },
  member: {
    profile: "Profil",
    historyorder: "Historique des commandes",
    changepassword: "Modifier le mot de passe",
    reportissue: "Signaler un problème",
    logout: "Déconnexion",
    name: "Nom",
    birthday: "Date de naissance",
    email: "Email",
    address: "Adresse",
    link: "Lier les comptes",
    unlink: "Dissocier les comptes",
    notlink: "Non lié",
    save: "Enregistrer",
    changesuccess: "Changement réussi",
    areyousureyouwanttounlink: "Êtes-vous sûr de vouloir dissocier?",
    unlinkseccessful: "Dissociation réussie",
  },
  historyOrder: {
    historyorder: "Historique des commandes",
    ordernumber: "Numéro de commande",
    createtime: "Heure de commande",
    eattype: "Type de repas",
    reservetime: "Heure de collecte",
    checkouttime: "Heure de paiement",
    memo: "Notes",
    total: "Total",
    confirming: "En cours de confirmation",
    accepted: "Accepté",
    rejected: "Rejeté",
    completed: "Terminé",
  },
  order: {
    required: "Obligatoire",
    available: "Choix disponibles",
    exceedsselectionlimit: "Dépasse la limite de choix",
    minqtyreached: "Quantité minimale atteinte",
    ok: "OK",
    mealsetsnotselected: "Des menus ne sont pas encore sélectionnés",
    flavorsnotselected: "Des saveurs ne sont pas encore sélectionnées",
    mealsetsover: "Le nombre de menus dépasse la limite de sélection",
    suretoreset:
      "Cet article sera réinitialisé. Êtes-vous sûr de vouloir continuer?",
    modifyflavors: "Modifier les saveurs",
    save: "Enregistrer",
    soldout: "Épuisé",
  },
  trade: {
    orderinformation: "Informations sur la commande",
    orderdetail: "Détails de la commande",
    ordernumber: "Numéro de commande",
    confirming: "En cours de confirmation",
    accepted: "Accepté",
    rejected: "Rejeté",
    completed: "Terminé",
    tablenumber: "Numéro de table",
    tablenum: "Numéro de table",
    name: "Nom",
    memo: "Notes",
    total: "Total",
    receiptinformation: "Informations sur le reçu",
    uniformnumber: "Numéro d'identification uniforme",
    vehiclenumber: "Identifiant de code-barres",
    backtomenu: "Retour au menu",
    rejectreason: "Raison du rejet",
    completetime: "Heure de finalisation",
    createtime: "Heure de commande",
    reservetime: "Heure de collecte",
    member: "Membre",
  },
  changePassword: {
    changepassword: "Changer le mot de passe",
    oldpassword: "Ancien mot de passe",
    newpassword: "Nouveau mot de passe",
    enterpasswordagain: "Ressaisir le mot de passe",
    pleaseentersamepassword: "Veuillez entrer le même mot de passe",
    changesuccess: "Changement réussi",
    save: "Enregistrer",
  },
  feedback: {
    reportissue: "Signaler un problème",
    pleaseentertheissueyouwanttoreport:
      "Veuillez entrer le problème que vous souhaitez signaler",
    submit: "Soumettre",
    thanksforyourreport: "Merci pour votre signalement",
  },
  register: {
    memberregister: "Inscription des membres",
    name: "Nom",
    phonenumber: "Numéro de portable",
    verifycode: "Code de vérification",
    password: "De passe",
    enterpasswordagain: "Ressaisir le mot de passe",
    pleaseentersamepassword: "Veuillez entrer le même mot de passe",
    getverifycode: "Obtenir le code",
    retry: "s Réessayer",
    register: "S'inscrire",
    alreadyhaveanaccount: "Déjà un compte",
    pleasetryagainlater: "Veuillez réessayer plus tard",
    pleaseenterphonenumber: "Veuillez entrer votre numéro de téléphone",
    pleasecheckyourmobilemessage: "Veuillez vérifier votre message mobile",
  },
  error: {
    sorrymessage:
      "Désolé, la page que vous recherchez n'existe pas ou a été supprimée.",
    goback: "Retourner à la page précédente.",
    urlexpired: "Cette URL a expiré.",
  },
};

export default fr;
