<template>
  <div>
    <div class="order-background" :class="[bgTrigger ? '' : 'hideOrder']"></div>
    <OrderEdit
      @closed="bgTrigger = isOrderShow = false"
      :currentFood="currentFood"
      :editingItemId="editingItemId"
      :trigger="isOrderShow"
      @saved="saveCart"
    ></OrderEdit>
    <div class="checkout">
      <div class="fix-header">
        <a class="fix-header-back-icon" @click="back">
          <i class="fas fa-chevron-left"></i>
        </a>
        <p class="fix-header-title">{{ store.StoreName }}</p>
      </div>
      <div class="fix-table">
        <div class="instore-table-number">
          <img src="../../imgs/table.png" alt="table" />
          {{ tableMapName }}
        </div>
        <div class="checkout-list-switchers">
          <div
            class="checkout-list-switcher ordered"
            @click="listSwitch('ordered')"
          >
            {{ $t("menu.orderedmeals") }}
          </div>
          <div
            class="checkout-list-switcher adding"
            @click="listSwitch('adding')"
          >
            {{ $t("menu.addmoremeals") }}
          </div>
        </div>
      </div>

      <div class="checkout-content">
        <div v-if="orderedCart" class="checkout-list checkout-ordered-list">
          <div
            class="checkout-announcement"
            v-if="store.IsShowCheckAnnouncement"
          >
            <div class="checkout-announcement-title">
              <i class="fas fa-info-circle"></i>
              <span class="ml-1">{{ $t("checkout.notice") }}</span>
            </div>
            <p>{{ store.CheckAnnouncement }}</p>
          </div>

          <div class="checkout-order-detail">
            <p class="checkout-container-title">
              {{ $t("checkout.orderdetails") }}
            </p>
            <div class="checkout-continue-order" @click="back">
              {{ $t("checkout.continueordering") }}
            </div>
          </div>
          <div v-for="(item, index) in orderedCart.SalDetTemps" :key="index">
            <div class="checkout-food">
              <div class="checkout-food-header">
                <div class="flex items-center">
                  <p class="checkout-food-qty inline-block">{{ item.Qty }}</p>
                  <p class="checkout-food-name inline-block">
                    {{ item.FoodName }}
                  </p>
                </div>
                <div class="flex items-center">
                  <p class="checkout-food-total">NT {{ item.Total }}</p>
                </div>
              </div>
              <div v-if="item.FoodTaste">
                <div class="checkout-food-taste">
                  {{ item.FoodTaste }}
                </div>
              </div>
              <div v-for="combo in item.Combos" :key="combo.ComboId">
                <div class="checkout-food-combo">
                  <div class="flex">
                    <div class="checkout-food-combo-icon">
                      <i class="fas fa-circle"></i>
                    </div>
                    <p>{{ combo.FoodName }}</p>
                  </div>
                  <p v-if="combo.Total != 0">+{{ combo.Total }}</p>
                </div>
                <div class="checkout-food-taste" v-if="combo.FoodTaste">
                  {{ combo.FoodTaste }}
                </div>
              </div>
              <div v-if="item.Memo">
                <div class="checkout-food-memo">
                  <div class="flex">
                    <div class="checkout-food-memo-content">
                      [{{ item.Memo }}]
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>

          <div class="checkout-total">
            <p class="checkout-container-title">{{ $t("checkout.total") }}</p>
            <p class="checkout-total-text">
              NT
              <span style="color: black">{{ orderedCart.SalTemp.Total }}</span>
            </p>
          </div>

          <hr v-if="store.HasTitleMemo" />
          <p
            v-if="store.HasTitleMemo"
            class="checkout-container-title"
            for="memo"
          >
            {{ $t("checkout.memo") }}
          </p>
          <div v-if="store.HasTitleMemo">
            {{ orderedCart.SalTemp.Memo }}
          </div>

          <hr />
          <div class="checkout-member">
            <p class="checkout-container-title">{{ $t("checkout.member") }}</p>
            <div
              v-if="orderedCart.SalTemp.CustomerName"
              class="checkout-text-group"
            >
              <label for="memberName">{{ $t("checkout.phonenumber") }}</label>
              <p id="memberName">{{ orderedCart.SalTemp.CustomerName }}</p>
            </div>
          </div>

          <hr />
          <div class="checkout-invoice">
            <p class="checkout-container-title">{{ $t("checkout.receipt") }}</p>
            <div
              v-if="orderedCart.SalTemp.UniformNumber"
              class="checkout-text-group"
            >
              <label for="uniformNumber">{{
                $t("checkout.uniformnumber")
              }}</label>
              <p id="uniformNumber">{{ orderedCart.SalTemp.UniformNumber }}</p>
            </div>
            <div
              v-if="orderedCart.SalTemp.VehicleNumber"
              class="checkout-text-group"
            >
              <label for="vehicleNumber">{{
                $t("checkout.vehiclenumber")
              }}</label>
              <p id="vehicleNumber">{{ orderedCart.SalTemp.VehicleNumber }}</p>
            </div>
          </div>
        </div>
        <div v-if="cart" class="checkout-list checkout-adding-list">
          <div
            class="checkout-announcement"
            v-if="store.IsShowCheckAnnouncement"
          >
            <div class="checkout-announcement-title">
              <i class="fas fa-info-circle"></i>
              <span class="ml-1">{{ $t("checkout.notice") }}</span>
            </div>
            <p>{{ store.CheckAnnouncement }}</p>
          </div>

          <div class="checkout-order-detail">
            <p class="checkout-container-title">
              {{ $t("checkout.orderdetails") }}
            </p>
          </div>
          <div v-for="item in cart.Items" :key="item.ItemId">
            <div class="checkout-food" @click="editFood(item)">
              <div class="checkout-food-header">
                <div class="flex items-center">
                  <button
                    class="checkout-food-delete"
                    @click="removeItem($event, item.ItemId)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                  <p class="checkout-food-name inline-block">
                    {{ item.FoodName }}
                  </p>
                  <p class="checkout-food-qty inline-block">{{ item.Qty }}</p>
                </div>
                <p class="checkout-food-total">NT {{ item.Total }}</p>
              </div>
              <div class="checkout-food-taste" v-if="item.FoodTaste">
                {{ item.FoodTaste }}
              </div>
              <div v-for="combo in item.Combos" :key="combo.ComboId">
                <div class="checkout-food-combo">
                  <div class="flex">
                    <div class="checkout-food-combo-icon">
                      <i class="fas fa-circle"></i>
                    </div>
                    <p>{{ combo.FoodName }}</p>
                  </div>
                  <p v-if="combo.Total != 0">+{{ combo.Total }}</p>
                </div>
                <div class="checkout-food-taste" v-if="combo.FoodTaste">
                  {{ combo.FoodTaste }}
                </div>
              </div>
              <div class="checkout-food-memo" v-if="item.Memo">
                <div class="flex">
                  <div class="checkout-food-memo-content">
                    [{{ item.Memo }}]
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>

          <div class="checkout-total">
            <p class="checkout-container-title">{{ $t("checkout.total") }}</p>
            <p class="checkout-total-text">
              NT <span>{{ cart.Total }}</span>
            </p>
          </div>

          <hr v-if="store.HasTitleMemo" />
          <p
            v-if="store.HasTitleMemo"
            class="checkout-container-title"
            for="memo"
          >
            {{ $t("checkout.memo") }}
          </p>
          <textarea
            v-if="orderedCart && store.HasTitleMemo"
            class="checkout-memo-input"
            id="memo"
            rows="3"
            v-model="orderedCart.SalTemp.Memo"
            placeholder="如果有任何過敏或不想添加的食材，請告訴我們"
          ></textarea>
          <hr />
          <div v-if="member" class="checkout-member">
            <p class="checkout-container-title">{{ $t("checkout.member") }}</p>
            <div class="checkout-text-group">
              <label for="memberName">{{ $t("checkout.name") }}</label>
              <p id="memberName">{{ member.Name }}</p>
            </div>
            <div class="checkout-text-group">
              <label for="cellphone">{{ $t("checkout.phonenumber") }}</label>
              <p id="cellphone">{{ member.Cellphone }}</p>
            </div>
          </div>
          <div v-else class="checkout-member">
            <p class="checkout-container-title">{{ $t("checkout.member") }}</p>
            <button
              type="btn"
              class="btn btn-border mb-3"
              @click="isLoginShow = true"
            >
              <p v-if="isMember">{{ $t("login.login") }}</p>
              <p v-else>{{ $t("join.joinstore") }}</p>
            </button>
            <login-modal
              :class="[isLoginShow ? 'flexOrder' : 'hideOrder']"
              :isMember="isMember"
              @saved="saved"
              @joined="joined"
              @closed="closed"
            ></login-modal>
          </div>
          <hr />
          <div class="checkout-invoice">
            <p class="checkout-container-title">{{ $t("checkout.receipt") }}</p>
            <div class="checkout-invoice-group">
              <div
                class="checkout-group"
                v-if="orderedCart && store.IsShowUniformNumber"
              >
                <input
                  class="uniform-number-input"
                  type="text"
                  v-model="orderedCart.SalTemp.UniformNumber"
                  :placeholder="$t('checkout.uniformnumber')"
                />
              </div>
              <div
                class="checkout-group"
                v-if="orderedCart && store.IsShowVehicleNumber"
              >
                <div class="vehicle-number-container">
                  <div class="vehicle-number-slash">/</div>
                  <input
                    class="vehicle-number-input"
                    type="text"
                    v-model="orderedCart.SalTemp.VehicleNumber"
                    :placeholder="$t('checkout.vehiclenumber')"
                  />
                </div>
              </div>
            </div>
            <hr />
          </div>
          <!-- <div class="checkout-pay">
        <p class="checkout-container-title">付款方式</p>
        <div class="checkout-checkbox">
          <input type="checkbox" id="payOnTake" />
          <label for="payOnTake">取餐時付款</label>
        </div>
      </div> -->
        </div>
      </div>

      <div v-if="currentList == 'adding'" class="checkout-btn">
        <button class="btn btn-full btn-border" @click="check">
          {{ $t("checkout.submitorder") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import loginModal from "@/components/LoginModal";
import OrderEdit from "@/components/OrderEdit";

export default {
  data() {
    return {
      store: {},
      cart: null,
      orderedCart: null,
      member: null,
      currentList: "",
      login: {},
      isLoginShow: false,
      isMember: true,
      salId: "",
      isOrderShow: false,
      currentFood: {},
      editingItemId: null,
      bgTrigger: false,
    };
  },
  components: {
    loginModal,
    OrderEdit,
  },
  computed: {
    isPassword() {
      return this.passwordType == "password";
    },
  },
  watch: {
    isLoginShow(value) {
      if (value) {
        $(".login-display").css("overflow", "auto");
        $("body").css("overflow", "hidden");
      } else {
        $(".login-display").css("overflow", "hidden");
        $("body").css("overflow", "auto");
      }
    },
    isOrderShow(value) {
      if (value) {
        $("body").css("overflow", "hidden");
        window.addEventListener("click", this.clickOnBackground);
      } else {
        $("body").css("overflow", "auto");
        window.removeEventListener("click", this.clickOnBackground);
      }
    },
    orderedCart() {
      this.tableMapName = this.orderedCart.SalTemp.TableMapName;
    },
    currentList(value) {
      const switches = document.querySelectorAll(".checkout-list-switcher");
      const lists = document.querySelectorAll(".checkout-list");
      switches.forEach((s) => {
        s.classList.remove("active");
      });
      lists.forEach((l) => {
        l.classList.remove("show");
      });
      if (value == "ordered") {
        const ordered = document.querySelector(".ordered");
        const orderedList = document.querySelector(".checkout-ordered-list");
        ordered.classList.add("active");
        orderedList.classList.add("show");
      } else {
        const adding = document.querySelector(".adding");
        const addingList = document.querySelector(".checkout-adding-list");
        adding.classList.add("active");
        addingList.classList.add("show");
      }
    },
    // "cart.Items"(value) {
    //   if (value.length == 0) {
    //     this.$router.push({
    //       name: "Menu",
    //       params: { keyid: this.$route.params.keyid },
    //     });
    //   }
    // },
  },
  methods: {
    clickOnBackground(e) {
      if (e.target.classList.contains("order-background")) {
        this.bgTrigger = false;
        this.isOrderShow = false;
      }
    },
    editFood(item) {
      this.bgTrigger = true;
      this.$api.foods
        .get(item.FoodId)
        .then((data) => {
          this.editingItemId = item.ItemId;
          this.currentFood = data;
          this.isOrderShow = true;
          this.$store.dispatch("updateLoading", false);
        })
        .catch((error) => {
          this.$swal(error.data.Message);
          this.bgTrigger = false;
        });
    },
    saveCart(cartItem) {
      let cart = this.$cookie.cart.get(this.$route.params.keyid);
      let itemIndex = cart.Items.findIndex((i) => {
        return cartItem.ItemId == i.ItemId;
      });
      cart.Items[itemIndex] = cartItem;
      this.$cookie.cart.set(this.$route.params.keyid, cart);
      this.cart = this.$cookie.cart.get(this.$route.params.keyid);
      this.bgTrigger = false;
      this.isOrderShow = false;
    },
    // checkInvoiceType(e, type) {
    //   const invoiceType = document.getElementById(`${type}`);
    //   if (e.target.checked) {
    //     invoiceType.removeAttribute("readonly");
    //   } else {
    //     invoiceType.setAttribute("readonly", true);
    //     invoiceType.value = "";
    //   }
    // },
    listSwitch(list) {
      if (list == this.currentList) {
        return;
      }
      this.currentList = list;
      window.scrollTo(0, 0);
    },
    check() {
      if (this.cart.Items.length == 0) {
        this.$swal(this.$t("menu.pleasechooseyourmeal"));
        return;
      }

      if (this.orderedCart.SalTemp.VehicleNumber) {
        this.orderedCart.SalTemp.VehicleNumber =
          this.orderedCart.SalTemp.VehicleNumber.replace(/\//g, "");
        const regex = /^[0-9A-Z.+-]{7}$/;
        if (!regex.test(this.orderedCart.SalTemp.VehicleNumber)) {
          this.$swal(this.$t("checkout.vehiclenumberisincorrect"));
          return;
        }
      }

      const vm = this;
      this.$swal({
        titleText: this.$t("checkout.areyousuretosubmittheorder"),
        icon: "question",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.cart.Memo = this.orderedCart.SalTemp.Memo;
          this.cart.UniformNumber = this.orderedCart.SalTemp.UniformNumber;
          this.cart.VehicleNumber = this.orderedCart.SalTemp.VehicleNumber;
          if (this.cart.VehicleNumber) {
            this.cart.VehicleNumber = "/" + this.cart.VehicleNumber;
          }
          this.cart.ReserveTime = `${this.$func.getDate()} ${this.$func.getTime()}`;
          let salId = this.$route.params.routeid;
          if (localStorage.getItem("salTempId")) {
            salId = localStorage.getItem("salTempId");
          }
          this.$api.checkouts
            .postInStore(salId, this.cart)
            .then((data) => {
              localStorage.setItem("orderFromInStore", true);
              vm.$cookie.cart.set(
                vm.$route.params.keyid,
                vm.$cookie.cart.getDefault()
              );
              vm.$router.push({
                name: "Trade",
                params: { keyid: vm.$route.params.keyid, id: data },
              });
            })
            .catch((error) => {
              vm.$swal(error.data.Message);
            });
        }
      });
    },
    back() {
      let routeData = this.$func.getRoute();
      this.$router.push({
        name: routeData.InStore + "Menu",
        params: {
          routeid: routeData.RouteId,
        },
      });
    },
    removeItem(e, id) {
      e.stopPropagation();
      const vm = this;
      this.$swal({
        titleText: this.$t("checkout.areyousureyouwanttodeletethis"),
        icon: "question",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          vm.cart = vm.$cookie.cart.removeItem(this.$route.params.keyid, id);
        }
      });
    },
    removeAllItem() {
      const vm = this;
      this.$swal({
        titleText: this.$t("checkout.areyousureyouwanttodeletetheentireorder"),
        icon: "question",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          vm.cart = vm.$cookie.cart.removeAllItem(this.$route.params.keyid);
        }
      });
    },
    checkToken() {
      const token = this.$cookie.member.getToken();
      const customer = this.$cookie.customer.get(this.$route.params.keyid);
      if (!token) {
        return 0;
      } else if (!customer || !customer.Name) {
        return 1;
      }
      return 2;
    },
    saved(ob) {
      const vm = this;
      this.$api.members
        .login(ob)
        .then((data) => {
          vm.$cookie.member.set(data);
          vm.member = vm.$cookie.member.get();
          vm.$store.dispatch("updateLoading", false);
          vm.closed();
        })
        .catch((error) => {
          vm.$swal(error.data.Message);
        });
    },
    joined() {
      const vm = this;
      this.$api.members
        .join()
        .then(() => {
          vm.closed();
        })
        .catch((error) => vm.$swal(error.data.Message));
    },
    closed() {
      this.isLoginShow = false;
    },
  },
  mounted() {},
  created() {
    window.scrollTo(0, 0);
    if (this.checkToken() == 2) {
      this.member = this.$cookie.member.get();
    } else if (this.checkToken() == 1) {
      this.isMember = false;
    }
    this.store = this.$cookie.store.get(this.$route.params.keyid);
    this.cart = this.$cookie.cart.get(this.$route.params.keyid);
    if (this.$route.path.includes("tables")) {
      this.salId = localStorage.getItem("salTempId");
    } else {
      this.salId = this.$route.params.routeid;
    }
    this.$api.inStore
      .get(this.store.DeviceId, this.salId)
      .then((data) => {
        this.orderedCart = data;
        if (this.orderedCart.SalTemp.VehicleNumber) {
          this.orderedCart.SalTemp.VehicleNumber =
            this.orderedCart.SalTemp.VehicleNumber.replace(/\//g, "");
        }
        this.$nextTick(function () {
          this.currentList = this.$route.query.list;
        });
        this.$store.dispatch("updateLoading", false);
      })
      .catch((error) => {
        if (error.status == "404") {
          this.$router.replace({ name: "InStoreInvalid" });
        }
      });
  },
};
</script>
