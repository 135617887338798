import {
  httpPostWithKey,
  httpPost,
  httpGet,
  httpPut,
  httpDelete,
} from "@/requests/http";

let path = "/members";
const url = process.env.VUE_APP_API_URL;

export default {
  login(ob) {
    return httpPostWithKey(`${path}/login`, ob);
  },
  register(ob) {
    return httpPostWithKey(`${path}/register`, ob);
  },
  join() {
    return httpPostWithKey(`${path}/join`);
  },
  changePassword(dTO) {
    return httpPost(`${url}${path}/password`, dTO);
  },
  forgetPassword(dTO) {
    return httpPost(`${url}${path}/forget-password`, dTO);
  },
  getInfo() {
    return httpGet(`${url}${path}/info`);
  },
  changeInfo(dTO) {
    return httpPost(`${url}${path}/info`, dTO);
  },
  getImage() {
    return httpGet(`${url}${path}/image`, null, {
      responseType: "blob",
    });
  },
  saveImage(formData) {
    return httpPut(`${url}${path}/image`, formData);
  },
  removeImage() {
    return httpDelete(`${url}${path}/image`);
  },
  searchLineId(id, item) {
    return httpPost(`${url}/${id}${path}/line`, item);
  },
  removeLineUser() {
    return httpDelete(`${url}${path}/lineuser`);
  },
};
