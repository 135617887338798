<template>
  <div class="member-edit">
    <div class="fix-header">
      <a class="fix-header-back-icon" @click="closed">
        <i class="fas fa-chevron-left"></i>
      </a>
      <p class="fix-header-title">{{ $t("member.profile") }}</p>
    </div>

    <form class="content" @submit.prevent="saved">
      <div class="input-inline-group">
        <label for="name">{{ $t("member.name") }}</label>
        <input id="name" type="text" v-model="member.Name" required />
      </div>
      <div class="input-inline-group">
        <label for="name">{{ $t("member.birthday") }}</label>
        <input id="name" type="date" v-model="member.Birth" />
      </div>
      <div class="input-inline-group">
        <label for="email">{{ $t("member.email") }}</label>
        <input id="email" type="email" v-model="member.Email" />
      </div>
      <div class="input-inline-group">
        <label for="address">{{ $t("member.address") }}</label>
        <input id="address" type="text" v-model="member.Address" />
      </div>
      <div v-if="isLiff" class="input-inline-group line-unbind-block">
        <span for="lineBind"
          ><i class="fab fa-line line-icon"></i>{{ $t("member.link") }}</span
        >
        <button
          v-if="lineBinding == 1"
          class="btn-unbind"
          type="button"
          @click="removeBind"
        >
          {{ $t("member.unlink") }}
        </button>
        <div v-else-if="lineBinding == 2" class="btn-unbind btn-disabled">
          {{ $t("member.notlink") }}
        </div>
      </div>
      <div class="pt-3">
        <button type="submit" class="btn btn-full btn-border">
          {{ $t("member.save") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import liff from "@line/liff";

export default {
  data() {
    return {
      member: {},
      isLiff: liff.isInClient(),
      lineBinding: 0,
    };
  },
  methods: {
    saved() {
      const vm = this;
      this.$api.members
        .changeInfo(this.member)
        .then(() => {
          vm.$cookie.member.set(vm.member);
          vm.$swal({
            titleText: this.$t("member.changesuccess"),
            icon: "success",
          });
          vm.closed();
        })
        .catch((error) => {
          vm.$swal(error.data.Message);
        });
    },
    closed() {
      this.$router.push({
        name: "Member",
        params: { keyid: this.$route.params.keyid },
      });
    },
    removeBind() {
      const vm = this;
      if (confirm(this.$t("member.areyousureyouwanttounlink"))) {
        this.$api.members.removeLineUser().then(() => {
          vm.lineBinding = 2;
          vm.$swal({
            titleText: this.$t("member.unlinkseccessful"),
            icon: "success",
          });
        });
      }
    },
  },
  mounted() {
    const vm = this;
    this.$api.members
      .getInfo()
      .then((data) => {
        vm.$store.dispatch("updateLoading", false);
        vm.member = data;
      })
      .catch((error) => {
        vm.$swal(error.data.Message);
        vm.$router.push({
          name: "Member",
          params: { keyid: vm.$route.params.keyid },
        });
      });
  },
  created() {
    const vm = this;
    const store = this.$cookie.store.get(this.$route.params.keyid);
    if (this.isLiff) {
      this.$api.members
        .searchLineId(store.DeviceId, {
          LineUserId: this.$store.state.userId,
        })
        .then(() => {
          vm.lineBinding = 1;
        })
        .catch(() => {
          vm.lineBinding = 2;
        });
    }
  },
};
</script>
