<template>
  <div class="history-order">
    <div class="fix-header">
      <a class="fix-header-back-icon" @click="closed">
        <i class="fas fa-chevron-left"></i>
      </a>
      <p class="fix-header-title">{{ $t("historyOrder.historyorder") }}</p>
    </div>
    <div class="content">
      <div class="history-order-groups">
        <div
          v-for="order in orders"
          :key="order.SalId"
          class="history-order-group"
          @click="goTrade(order.SalId)"
        >
          <div class="history-order-header" :class="getStatusClass(order)">
            <p>
              {{ order.OnlineOrderStatusText }}
            </p>
          </div>
          <!-- 增加一個確認訂單狀態如未結單轉為已過期？ -->

          <div class="history-order-content">
            <div class="history-order-text-group">
              <label for="salNumber">{{
                $t("historyOrder.ordernumber")
              }}</label>
              <p id="salNumber">{{ order.SalNumber }}</p>
            </div>
            <div class="history-order-text-group">
              <label for="onlineOrderTimeText">{{
                $t("historyOrder.createtime")
              }}</label>
              <p id="onlineOrderTimeText">{{ order.OnlineOrderTimeText }}</p>
            </div>
            <hr />
            <div class="history-order-text-group">
              <label for="eatTypeText">{{ $t("historyOrder.eattype") }}</label>
              <p id="eatTypeText">{{ order.EatTypeText }}</p>
            </div>
            <div
              v-if="
                (order.OnlineOrderStatusType == 0 ||
                  order.OnlineOrderStatusType == 1) &&
                order.EatType != 0
              "
              class="history-order-text-group"
            >
              <label for="reserveTimeText">{{
                $t("historyOrder.reservetime")
              }}</label>
              <p id="reserveTimeText">{{ order.EstimatedPickupTimeText }}</p>
            </div>
            <div
              class="history-order-text-group"
              v-if="order.OnlineOrderStatusType == 3"
            >
              <label v-if="order.EatType != 0" for="salTimeText">{{
                $t("historyOrder.reservetime")
              }}</label>
              <label v-else for="salTimeText">{{
                $t("historyOrder.checkouttime")
              }}</label>
              <p id="salTimeText">{{ order.SalTimeText }}</p>
            </div>
            <!-- <div class="history-order-text-group">
              <label for="customerName">付款方式</label>
              <p id="customerName">{{ order.Memo }}</p>
            </div> -->
            <div class="history-order-text-group">
              <label for="memo">{{ $t("historyOrder.memo") }}</label>
              <p id="memo">{{ order.Memo }}</p>
            </div>
            <hr />
            <p class="history-order-total">
              {{ $t("historyOrder.total") + " NT"
              }}<span>{{ order.Total }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orders: [],
    };
  },
  methods: {
    getStatusClass(order) {
      switch (order.OnlineOrderStatusType) {
        case 1:
          return "accept";
        case 2:
          return "reject";
        case 3:
          return "done";
        default:
          return "wait";
      }
    },
    checkToken() {
      const token = this.$cookie.member.getToken();
      const customer = this.$cookie.customer.get(this.$route.params.keyid);
      if (!token) {
        this.$router.push({
          name: "Login",
          params: { keyid: this.$route.params.keyid, beforepath: "Checkout" },
        });
      } else if (!customer || !customer.Name) {
        this.$router.push({
          name: "Join",
          params: { keyid: this.$route.params.keyid, beforepath: "Checkout" },
        });
      }
    },
    closed() {
      this.$router.push({
        name: "Member",
        params: { keyid: this.$route.params.keyid },
      });
    },
    goTrade(id) {
      this.$router.push({
        name: "Trade",
        params: { keyid: this.$route.params.keyid, id: id },
      });
    },
  },
  created() {
    this.checkToken();
  },
  mounted() {
    const vm = this;
    this.$api.historyOrders
      .getAll()
      .then((data) => {
        vm.$store.dispatch("updateLoading", false);
        vm.orders = data;
        vm.orders.forEach((order) => {
          order.OnlineOrderStatusText =
            this.$enum.onlineOrderStatusType.getByValue(
              order.OnlineOrderStatusType
            ).text;
        });
      })
      .catch((error) => vm.$swal(error));
  },
};
</script>
